import request from '@/utils/request'
const messageApi = {
  // 消息中心
  messagePage: '/message/page',
  // 批量添加
  updateReadSts: 'message/updateReadSts'
}
export function messagePage (data) {
  return request({
    url: messageApi.messagePage,
    method: 'post',
    data: data
  })
}

export function updateReadSts (data) {
  return request({
    url: messageApi.updateReadSts,
    method: 'post',
    data: data
  })
}
