<template>
  <div>
    <div class="message-container">
      <!-- tab栏 -->
      <div class="tabs">
        <a-tabs v-model="type" @change="callback($event)">
          <a-tab-pane :key="0">
            <span slot="tab">
              全部消息
              <a-badge :dot="show">
                {{ '(' + sum + ')' }}
              </a-badge>
            </span>
          </a-tab-pane>
          <a-tab-pane :key="1">
            <span slot="tab">
              公告通知
              <a-badge :dot="notiShow">
                {{ '(' + sum1 + ')' }}
              </a-badge>
            </span>
          </a-tab-pane>
          <a-tab-pane :key="2">
            <span slot="tab">
              功能发布
              <a-badge :dot="tipShow">
                {{ '(' + sum2 + ')' }}
              </a-badge>
            </span>
          </a-tab-pane>
          <a-tab-pane :key="3">
            <span slot="tab">
              数据预警
              <a-badge :dot="warnShow">
                {{ '(' + sum3 + ')' }}
              </a-badge>
            </span>
          </a-tab-pane>
        </a-tabs>
      </div>
      <MessageTable ref="child" @changeNum="changeNum"></MessageTable>
    </div>
  </div>
</template>

<script>
import MessageTable from './components/MessageTable'
export default {
  name: 'Message',
  components: { MessageTable },
  data () {
    return {
      // 消息类型
      type: 0,
      // 	全部未读
      sum: 0,
      // 公告通知未读
      sum1: 0,
      // 功能发布未读
      sum2: 0,
      // 数据预警未读
      sum3: 0,
      show: false,
      tipShow: false,
      warnShow: false,
      notiShow: false
    }
  },
  created () {
    if (this.$route.query && this.$route.query.type) {
      this.type = +this.$route.query.type
    }
  },
  watch: {
    // 全部消息小红点显示
    sum: {
      async handler (val) {
        if (val > 0) {
          this.show = true
        } else {
          this.show = false
        }
      }
    },
    // 公告通知小红点显示
    sum1: {
      async handler (val) {
        if (val > 0) {
          this.notiShow = true
        } else {
          this.notiShow = false
        }
      }
    },
    // 功能上线小红点显示
    sum2: {
      async handler (val) {
        if (val > 0) {
          this.tipShow = true
        } else {
          this.tipShow = false
        }
      }
    },
    // 数据预警小红点显示
    sum3: {
      async handler (val) {
        if (val > 0) {
          this.warnShow = true
        } else {
          this.warnShow = false
        }
      }
    }
  },
  methods: {
    // tab切换
    async callback (e) {
      this.$router.push({
        query: {
          type: e
        }
      })
      this.$refs.child.onSelectCheckbox()
    },
    changeNum (data) {
      const { sum, sum1, sum2, sum3 } = data
      this.sum = sum
      this.sum1 = sum1
      this.sum2 = sum2
      this.sum3 = sum3
    }
  }
}
</script>

<style scoped lang="less">
.message-container {
  padding: 0 0 0 10px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  /deep/.ant-tabs-tab {
    width: 99px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
  /deep/.ant-tabs-tab-active {
    width: 101px;
    height: 32px;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 400;
    color: @primary-color;
    line-height: 20px;
  }
}
</style>
