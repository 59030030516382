<template>
  <div class="message-table-wrapper">
    <!-- 选项行 -->
    <div class="message-table-status">
      <div class="message-left">
        <span class="message-status-text"> 消息状态 </span>
        <a-select class="message-select" v-model="readSts" size="small" @change="handleChange($event)">
          <a-select-option :value="2"> 全部 </a-select-option>
          <a-select-option :value="1"> 已读 </a-select-option>
          <a-select-option :value="0"> 未读 </a-select-option>
        </a-select>
      </div>
      <div>
        <a-button :disabled="!hasSelected" @click="read">
          <span>批量已读</span>
        </a-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="message-table-whole">
      <a-table
        style="background: #f3f4f9"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        size="middle"
        :customRow="customRow"
        :rowKey="(record) => record.id"
        :data-source="tableList"
        bordered
      >
        <!-- 小红点 -->
        <!-- <div slot="dot" slot-scope="record">
          <readDotSvg class="message-table-readDot" v-if="+record.readSts === 0"></readDotSvg>
        </div> -->
        <!-- 消息内容 -->
        <div slot="messageTitle" slot-scope="record">
          <!-- 在这里进行title颜色变换的判断 -->
          <div
            style="width: 500px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
            :class="{ readSts1: +record.readSts === 1, readSts0: +record.readSts === 0 }"
          >
            <readDotSvg class="message-table-readDot" v-if="+record.readSts === 0"></readDotSvg>
            {{ record.title }}
          </div>
        </div>
        <!-- 消息类型 -->
        <template slot="type" slot-scope="record">
          <a-tag :color="+record === 1 ? 'cyan' : +record === 2 ? 'purple' : 'pink'">
            {{ +record === 1 ? '公告通知' : +record === 2 ? '功能发布' : '数据预警' }}
          </a-tag>
        </template>
        <!-- 日期 -->
        <template slot="publishTime" slot-scope="publishTime">
          <span class="message-publish-time">{{ publishTime }}</span>
        </template>
      </a-table>
    </div>
    <a-pagination
      class="pagination"
      v-if="tableList.length > 0"
      show-size-changer
      :current="query.page"
      :total="total"
      :pageSize="query.limit"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
    />
    <!-- 弹窗 -->
    <MessageModal
      v-if="MessageModalVisible"
      :visible="MessageModalVisible"
      :messagePart="messagePart"
      @modalCancel="MessageModalVisible = false"
    ></MessageModal>
  </div>
</template>

<script>
import readDotSvg from '@/assets/icons/readDot.svg?inline'
import { messagePage, updateReadSts } from '@/api/message'
import MessageModal from '../MessageModal'
export default {
  components: { MessageModal, readDotSvg },
  name: 'MessageTable',
  data () {
    return {
      isLoading: false,
      // 类型
      type: undefined,
      unReadMessageNum: undefined,
      query: {
        page: 1,
        limit: 10
      },
      // 全部
      total: undefined,
      // 默认全部信息
      readSts: 2,
      // 可选框选中的key
      selectedRowKeys: [],
      // 数据源
      tableList: [],
      // 列选项
      columns: [
        // {
        //   key: 'dot',
        //   scopedSlots: { customRender: 'dot' },
        //   width: 50
        // },
        {
          title: '消息标题',
          key: 'title',
          scopedSlots: { customRender: 'messageTitle' },
          width: '49%'
        },
        {
          title: '消息类型',
          key: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: '35%'
        },
        {
          title: '发布时间',
          dataIndex: 'publishTime',
          key: 'publishTime',
          width: '15%'
        }
      ],
      // 点击行弹窗默认
      MessageModalVisible: false,
      messagePart: {}
    }
  },
  computed: {
    // 选中的
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (val.query && val.query.type) {
          this.type = +val.query.type
        }
        !this.type && (this.type = 0)
        this.readSts = 2
        this.getPageList()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 获得消息列表
    async getPageList () {
      this.isLoading = true
      const { data = {} } = await messagePage({
        type: !this.type ? undefined : this.type,
        readSts: this.readSts === 2 ? undefined : this.readSts,
        limit: this.query.limit,
        page: this.query.page
      })
      this.$emit('changeNum', data)
      const { items = [] } = data
      this.tableList = items
      this.total = data.total
      this.isLoading = false
    },
    // 批量已读
    async read () {
      // 已读接口
      const resd = await updateReadSts({
        readSts: 1,
        messageId: this.selectedRowKeys
      })
      // 返回总未读数量,修改vuex
      this.unReadMessageNum = resd.data.unReadMessageNum
      if (this.unReadMessageNum === 0) {
        this.$store.commit('SET_UNREADMESSAGE', '0')
      } else {
        this.$store.commit('SET_UNREADMESSAGE', '1')
      }
      this.loading = false
      this.selectedRowKeys = []
      // 渲染消息列表
      this.getPageList()
    },
    // 可选框选中
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 切换页面可选框为空
    onSelectCheckbox () {
      this.selectedRowKeys = []
    },
    // 行内点击事件
    customRow (record) {
      return {
        on: {
          // 点击
          click: () => {
            this.messagePart = JSON.parse(JSON.stringify(record))
            this.MessageModalVisible = true
            // 调接口
            updateReadSts({
              readSts: 1,
              messageId: [record.id]
            }).then((res) => {
              // 返回总未读数量,修改vuex
              this.unReadMessageNum = res.data.unReadMessageNum
              if (this.unReadMessageNum === 0) {
                this.$store.commit('SET_UNREADMESSAGE', '0')
              } else {
                this.$store.commit('SET_UNREADMESSAGE', '1')
              }
            })
            // 渲染消息列表
            this.getPageList()
          }
        }
      }
    },
    // readsts切换
    async handleChange (e) {
      // 获取消息列表
      this.isLoading = true
      const { data = {} } = await messagePage({
        type: !this.type ? undefined : this.type,
        readSts: e === 2 ? undefined : e,
        limit: this.query.limit,
        page: this.query.page
      })
      this.$emit('changeNum', data)
      const { items = [] } = data
      this.tableList = items
      this.total = data.total
      this.isLoading = false
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getPageList()
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getPageList()
    }
  }
}
</script>

<style scoped lang="less">
.message-table-wrapper {
  margin-top: -16px;
  .message-table-status {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding: 0 16px;
    line-height: 50px;
    .message-left {
      display: flex;
      align-items: center;
      .message-status-text {
        margin-right: 8px;
      }
      .message-select {
        margin: 0 0 0 20px;
        ::v-deep .ant-select-selection {
          width: 70px;
          height: 30px;
          line-height: 30px;
        }
        /deep/.ant-select .ant-select-enabled {
          position: absolute;
          width: 95px;
          line-height: 20px;
          background: #fafafa;
          border-radius: 8px;
          border: 1px solid #e6e6e6;
        }
        /deep/.ant-select-selection-selected-value {
          color: #b4b4b4;
          width: 80%;
          font-size: 14px;
          font-weight: 400;
          line-height: 29px;
        }
      }
    }
  }
  .message-table-whole {
    cursor: pointer;
    .readSts1 {
      font-size: 14px;
      font-weight: 600;
      color: #8c8c8c;
      line-height: 22px;
    }
    .readSts0 {
      font-size: 14px;
      font-weight: 600;
      color: #1f1f1f;
      line-height: 22px;
    }
    /deep/.ant-table-tbody {
      background: #fff;
    }
    .message-table-readDot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #f93920;
      flex-shrink: 0;
    }
    /deep/.ant-table-column-title {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    /deep/.ant-table-thead {
      background: #f3f4f9;
    }
    .message-publish-time {
      width: 130px;
      height: 17px;
      font-size: 14px;
      color: #333333;
      line-height: 17px;
    }
    /deep/.ant-table-row-cell-break-word {
      cursor: pointer;
      width: 112px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
  .pagination {
    padding: 30px 10px;
  }
}
</style>
